import Image from "next/image";

import Link from "next/link";
import React, { useEffect, useState } from "react";
import FooterLogo from "../../assets/images/VG-Footer.svg";
import FooterLogoMbl from "../../assets/images/VG-Footer-MBL.svg";
import {
  PhoneIcon,
  MailIcon,
  LocationMarkerIcon,
} from "@heroicons/react/solid";
import Script from "next/script";
import axios from "axios";
import { fetchFooterData } from "../../services/dynamicDataFetch";
import { strapiImageUrl } from "../../services/generateImageUrl";

const _navigation = {
  contact: [
    {
      name: "+61 0415941903",
      icon: (
        <PhoneIcon className="h-6 w-6 bg-orange-gradient rounded-full p-1" />
      ),
      href: "/",
    },
    {
      name: "contact@virtual-gravity.com",
      icon: (
        <MailIcon className="h-6 w-6 bg-orange-gradient rounded-full p-1" />
      ),
      href: "/services",
    },
    {
      name: `51 Monash St Sunshine, VIC 3020, Australia`,
      icon: (
        <LocationMarkerIcon className="h-6 w-6 bg-orange-gradient rounded-full p-1" />
      ),
      href: "/career",
    },
    // { name: 'About Us', href: '/about' },
    // { name: 'Blogs', href: '/blogs' },
  ],
  menu: [
    { name: "Career", href: "/career" },
    { name: "Faqs", href: "/faqs" },
  ],
  letsConnect: [
    { name: "Contact Us", href: "/contact" },
    { name: "FAQs", href: "/faqs" },
  ],
  terms: [
    { name: "Terms & Conditions", href: "/terms-and-conditions" },
    { name: "Privacy Policy", href: "/privacy-policy" },
  ],
  social: [
    {
      name: "Twitter",
      // href: process.env.twitter_link,
      href: "https://twitter.com/VirtualGravity3",

      icon: (props: any) => (
        <svg
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <circle cx="13.5" cy="13.5" r="13.5" fill="#F36012" />
          <path
            d="M22.6146 8.33163C22.3476 8.45705 22.073 8.5629 21.7925 8.64883C22.1246 8.25109 22.3779 7.78309 22.5324 7.27096C22.5671 7.15617 22.5312 7.03052 22.4421 6.95544C22.3531 6.8803 22.2294 6.8713 22.1314 6.93279C21.5356 7.30696 20.8928 7.57586 20.2187 7.7331C19.5398 7.03063 18.6176 6.63086 17.6643 6.63086C15.652 6.63086 14.0148 8.36426 14.0148 10.4949C14.0148 10.6627 14.0249 10.8296 14.0447 10.9941C11.5476 10.762 9.22616 9.46243 7.632 7.39191C7.57519 7.31811 7.48766 7.27834 7.39806 7.28594C7.3084 7.29338 7.22784 7.34691 7.18254 7.42919C6.85921 8.01663 6.68828 8.68849 6.68828 9.37209C6.68828 10.3031 7.00223 11.1865 7.55682 11.8768C7.38819 11.815 7.22455 11.7377 7.06837 11.6459C6.98452 11.5965 6.88219 11.5972 6.79889 11.6478C6.71553 11.6984 6.66327 11.7914 6.66108 11.8931C6.6607 11.9102 6.6607 11.9273 6.6607 11.9447C6.6607 13.3345 7.36713 14.5857 8.44718 15.2677C8.35439 15.2579 8.26166 15.2436 8.16953 15.225C8.07455 15.2058 7.97688 15.241 7.91283 15.3177C7.84867 15.3944 7.82744 15.5012 7.857 15.5987C8.25678 16.9203 9.28604 17.8924 10.5303 18.1887C9.49833 18.8731 8.31814 19.2316 7.07923 19.2316C6.82072 19.2316 6.56073 19.2155 6.30627 19.1837C6.17987 19.1677 6.05901 19.2468 6.01596 19.374C5.97291 19.5014 6.01853 19.6433 6.12607 19.7163C7.71766 20.7969 9.55799 21.368 11.448 21.368C15.1635 21.368 17.4879 19.5128 18.7835 17.9565C20.399 16.0159 21.3256 13.4473 21.3256 10.9094C21.3256 10.8034 21.324 10.6963 21.3209 10.5896C21.9583 10.0811 22.5071 9.46574 22.9537 8.75846C23.0215 8.65104 23.0141 8.50907 22.9356 8.41019C22.8571 8.31125 22.7263 8.27925 22.6146 8.33163Z"
            fill="#012346"
          />
        </svg>
      ),
    },
    {
      name: "Facebook",
      // href: process.env.facebook_link,
      href: "https://www.facebook.com/people/Virtual-Gravity",

      icon: (props: any) => (
        <svg
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <circle cx="13.5" cy="13.5" r="13.5" fill="#F36012" />
          <path
            d="M8.74406 14.5868H10.7171V22.7097C10.7171 22.8701 10.8471 23 11.0075 23H14.3529C14.5133 23 14.6432 22.8701 14.6432 22.7097V14.6251H16.9115C17.059 14.6251 17.183 14.5144 17.1999 14.3679L17.5444 11.3775C17.5538 11.2952 17.5278 11.2128 17.4727 11.1511C17.4176 11.0893 17.3388 11.0539 17.256 11.0539H14.6434V9.17937C14.6434 8.61428 14.9476 8.32774 15.5478 8.32774C15.6333 8.32774 17.256 8.32774 17.256 8.32774C17.4164 8.32774 17.5463 8.19773 17.5463 8.03741V5.29247C17.5463 5.1321 17.4164 5.00215 17.256 5.00215H14.9018C14.8852 5.00134 14.8483 5 14.794 5C14.3855 5 12.9656 5.08019 11.8441 6.11199C10.6014 7.2554 10.7741 8.62445 10.8154 8.86181V11.0539H8.74406C8.58368 11.0539 8.45374 11.1838 8.45374 11.3442V14.2964C8.45374 14.4568 8.58368 14.5868 8.74406 14.5868Z"
            fill="#012346"
          />
        </svg>
      ),
    },
    {
      name: "LinkedIn",
      // href: process.env.linkedin_link,
      href: "https://www.linkedin.com/company/virtualgravity/",

      icon: (props: any) => (
        <svg
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <circle cx="13.5" cy="13.5" r="13.5" fill="#F36012" />
          <path
            d="M8.95711 10.4707H5.54434C5.39287 10.4707 5.27014 10.5935 5.27014 10.7449V21.7087C5.27014 21.8602 5.39287 21.9829 5.54434 21.9829H8.95711C9.10858 21.9829 9.23131 21.8602 9.23131 21.7087V10.7449C9.23131 10.5935 9.10858 10.4707 8.95711 10.4707Z"
            fill="#012346"
          />
          <path
            d="M7.25201 5.01953C6.01024 5.01953 5 6.02867 5 7.26907C5 8.51002 6.01024 9.51954 7.25201 9.51954C8.49279 9.51954 9.5022 8.50996 9.5022 7.26907C9.50226 6.02867 8.49279 5.01953 7.25201 5.01953Z"
            fill="#012346"
          />
          <path
            d="M17.6378 10.1973C16.2671 10.1973 15.2538 10.7865 14.6392 11.456V10.744C14.6392 10.5925 14.5165 10.4698 14.365 10.4698H11.0967C10.9452 10.4698 10.8225 10.5925 10.8225 10.744V21.7077C10.8225 21.8592 10.9452 21.9819 11.0967 21.9819H14.502C14.6535 21.9819 14.7762 21.8592 14.7762 21.7077V16.2832C14.7762 14.4553 15.2727 13.7431 16.547 13.7431C17.9347 13.7431 18.045 14.8848 18.045 16.3773V21.7078C18.045 21.8593 18.1677 21.982 18.3192 21.982H21.7258C21.8772 21.982 22 21.8593 22 21.7078V15.694C22 12.9759 21.4817 10.1973 17.6378 10.1973Z"
            fill="#012346"
          />
        </svg>
      ),
    },
  ],
};

export default function Footer() {
  const [footerData, setFooterData] = useState<any>();
  const bgAnimation = footerData?.bgAnimation?.data[0]?.attributes?.url;
  const copyRightText = footerData?.copyRightText;
  const contactData = footerData?.contacts?.data?.attributes;
  const socialLinks = footerData?.socialLinks?.data;
  const mobileLogo = footerData?.companyLogo?.data?.attributes?.url;
  useEffect(() => {
    const loadScriptByURL = (id: string, url: string, callback: any) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.defer = true;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`,
      () => {}
    );
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const footerData = await fetchFooterData();
      setFooterData(footerData);
    };
    fetchData();
  }, []);

  return (
    <>
      {footerData && (
        <footer aria-labelledby="footer-heading" className="h-full">
          <h2 id="footer-heading" className="sr-only">
            Footer
          </h2>
          <div className="bg-black md:h-[400px] h-full overflow-hidden">
            <section className="bg-radial-gradient-dark w-full relative md:h-[400px] h-full flex items-center justify-center">
              <div className="flex md:flex-row max-w-7xl w-full p-10 xl:gap-[550px] gap-y-80  xl:justify-start md:justify-between  justify-center  flex-col  items-start relative">
                {contactData ? (
                  <div className="flex flex-col gap-6 lg:w-[388px] z-20">
                    <div className="flex flex-col gap-4 md:w-[304px] ">
                      <div className="flex gap-6 items-center">
                        {contactData?.mailImage?.data?.attributes?.url && (
                          <Image
                            alt="logo"
                            height={24}
                            width={24}
                            src={strapiImageUrl(
                              contactData?.mailImage?.data?.attributes?.url
                            )}
                          />
                        )}
                        <a
                          href={`mailto:${
                            contactData?.Email || "virtual@gravity.com"
                          }`}
                          className="text-white"
                        >
                          {contactData?.Email || "virtual@gravity.com"}
                        </a>
                      </div>
                      <div className="flex gap-6 items-center">
                        {contactData?.contactImage?.data?.attributes?.url && (
                          <Image
                            alt="logo"
                            height={24}
                            width={24}
                            src={strapiImageUrl(
                              contactData?.contactImage?.data?.attributes?.url
                            )}
                          />
                        )}
                        <p className="text-white">{contactData?.Phone}</p>
                      </div>
                      <div className="flex gap-6 items-center">
                        {contactData?.locationImage?.data?.attributes?.url && (
                          <Image
                            alt="logo"
                            height={24}
                            width={24}
                            src={strapiImageUrl(
                              contactData?.locationImage?.data?.attributes?.url
                            )}
                          />
                        )}
                        <p className="text-white">
                          {contactData?.Street +
                            contactData?.State +
                            contactData?.Country}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="md:absolute md:block hidden left-1/2 -translate-y-1/2 -translate-x-1/2 top-1/2 ">
                  {bgAnimation && (
                    <Image
                      alt="logo"
                      height={748.63}
                      width={748.63}
                      src={strapiImageUrl(bgAnimation)}
                    />
                  )}
                </div>

                <div className="absolute md:hidden flex justify-center items-center left-1/2 -translate-y-1/2 -translate-x-1/2 top-1/2 w-full h-full">
                  {mobileLogo && (
                    <Image
                      height={186}
                      width={380}
                      alt="logo"
                      src={strapiImageUrl(mobileLogo)}
                    />
                  )}
                </div>



                <div className="flex md:flex-col gap-4 z-20 justify-between w-full md:w-auto">
                  <div className="flex flex-col gap-4 ">
                    {footerData &&
                      footerData?.menu_items?.data?.map(
                        (item: any, index: any) => {
                          return (
                            <div
                              className="flex gap-6 items-center"
                              key={index}
                            >
                              {/* {item.icon({ className: "h-6 w-6 bg-orange-gradient rounded-full p-1" })} */}
                              {/* <Image src={item.href}/> */}
                              <Link href={item?.attributes?.url}>
                                <p className="text-white hover:text-secondary cursor-pointer">
                                  {item?.attributes?.Label}
                                </p>
                              </Link>
                            </div>
                          );
                        }
                      )}
                  </div>
                    <div className=" md:border-t border-white border-opacity-[8%] flex flex-col md:flex-row gap-4 md:gap-8 py-4">
                      {socialLinks?.map((item: any, index: any) => (
                        <div key={index}>
                          <Link href={`${item?.attributes?.href}`}>
                            <a target="_blank">
                              {item?.attributes?.icon?.data?.attributes
                                ?.url && (
                                <Image
                                  height={24}
                                  width={24}
                                  alt={
                                    item?.attributes?.icon?.data?.attributes
                                      ?.name
                                  }
                                  className="w-[200px] h-auto"
                                  src={strapiImageUrl(
                                    item?.attributes?.icon?.data?.attributes
                                      ?.url
                                  )}
                                />
                              )}
                            </a>
                          </Link>
                        </div>
                      ))}
                    </div>
                </div>
              </div>
              <div className="absolute bottom-2 pt-4 md:mt-0 text-white font-montserrat text-[12px]">
                © {new Date().getFullYear()} {copyRightText}
              </div>
            </section>
          </div>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.ga_measurement_id}`}
            strategy="afterInteractive"
            defer
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.ga_measurement_id}');
          `}
          </Script>
        </footer>
      )}
    </>
  );
}
