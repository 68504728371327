import axios from "axios"
import qs from 'qs';
import { generatePopulateQuery } from "./queryStringUrl";

// fetch Career Data
export const fetchCareerData = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/job-positions?populate=deep`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching Career data", error);
        return error
    }
}

// fetch Client Data
export const fetchClientData = async () => {
    try {
        const response = await axios.get(
          `${process.env.admin_url}/api/clients?populate=deep&filters[isFeatured]=true&pagination[limit]=3`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching Client data", error);
        return error
    }
}

// fetch Service Data
export const fetchOurServicesData = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/services?populate=deep`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching Services data", error);
        return error
    }
}

// fetch individual service data by slug
export const fetchServiceBySlug = async (slug: string) => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/services?populate=deep&filters[slug]=${slug}`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching Service data by slug", error);
        return error
    }
}

// fetch Technology Data
export const fetchOurTechnologies = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/our-technologies?populate=deep`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching technology data", error);
        return error
    }
}

// fetch Testimonial Data
export const fetchTestimonial = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/testimonials?populate=deep`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching testimonial data", error);
        return error
    }
}

// fetch Testimonial Data
export const fetchRequiredMedia = async(mediaName: string) => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/multiple-used-medias?populate=deep&filter[${mediaName}]`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching testimonial image", error);
        return error
    }
}

// fetch Portfolio Data
export const fetchPortfolioData = async () => {
    try {
        // const response = await axios.get(
        //     `${process.env.admin_url}/api/testimonials?populate=deep`
        // );
        // return response?.data
        return
    } catch (error) {
        console.log("Error fetching portfolio data", error);
        return error
    }
}

// fetch blogs by currentPage and pageSize
export const fetchArticles = async (currentPage: number, pageSize:number) => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/blogs?populate=deep&sort[0]=createdAt%3Adesc&pagination[page]=${currentPage}&pagination[pageSize]=${pageSize}`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching blogs/articles data", error);
        return error
    }
}

// fetch Blogs
export const fetchFeaturedArticles = async (pageSize: number) => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/blogs?populate=deep&filters[is_featured]=true&sort[0]=createdAt%3Adesc&pagination[pageSize]=${pageSize}`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching featured blogs/articles data", error);
        return error
    }
}

// fetch singleBlog
export const fetchSingleArticle = async (slug: string) => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/blogs?populate=deep&filters[slug]=${slug}`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching blog/article data by slug", error);
        return error
    }
}
// &sort[0]=createdAt%3Adesc&pagination[page]=1&pagination[pageSize]=${currentPage}&pagination[pageSize]=${pageSize}
// fetch products
export const fetchProductDataWithPagination = async (currentPage:number, pageSize:number) => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/products?populate=deep&sort[0]=createdAt&pagination[page]=${currentPage}&pagination[pageSize]=${pageSize}`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching product data", error);
        return error
    }
}

export const fetchProductData = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/products?populate=deep`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching product data", error);
        return error
    }
}

// fetch featured Product Data
export const fetchFeaturedProductData = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/products?populate=deep&filters[is_featured]=true`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching Client data", error);
        return error
    }
}


// fetch team-members
export const fetchTeamMembers = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/team-members?populate=deep`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching team data", error);
        return error
    }
}

// fetch faqs
export const fetchFaqData = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/faqs?populate=deep`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching FAQ data", error);
        return error
    }
}

// fetch pricing data
export const fetchPricingData = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/pricing-plans?populate=deep`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching Price data", error);
        return error
    }
}

// fetch nav bar data
export const fetchNavBarData = async () => {
    try {
        const response = await axios.get(
          `${process.env.admin_url}/api/headers?populate=deep`
        );
        return response?.data?.data?.[0]?.attributes
    } catch (error) {
        console.log("error", error);
        return error;
    }
}


export const fetchFooterData = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/footers?populate=deep`
        );

        return response?.data?.data?.[0]?.attributes
    } catch (error) {
      console.log("error");
      return error;
    }
}


export const fetchJobsFeature = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/job-feature?populate=deep`
        );
        return response?.data
    } catch (error) {
      console.log("error");
      return error;
    }
}

export const fetchCareerPageData = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/career-page?populate=deep`
        );
        return response?.data
    } catch (error) {
      console.log("error");
      return error;
    }
}


export const fetchPortfolioProductData = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/products?populate=deep`
        );
        return response?.data
    } catch (error) {
      console.log("error");
      return error;
    }
}


export const fetchFeaturedProducts = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/products?populate=deep&filters[is_featured]=true`
        );
        return response?.data
    } catch (error) {
      console.log("error");
      return error;
    }
}

export const fetchPortfolioPageData = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/portfolio?populate=deep`
        );
        return response?.data
    } catch (error) {
      console.log("error");
      return error;
    }
}


export const fetchOngoingProjects = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/ongoing-projects?populate=deep`
        );
        return response?.data
    } catch (error) {
      console.log("error");
      return error;
    }
}


export const fetchContactPageData = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/contact?populate=deep`
        );
        return response?.data
    } catch (error) {
      console.log("error");
      return error;
    }
}

export const fetchProductDataBySlug = async (slug: string) => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/products?filters[slug][$eq]=${slug}&populate=deep`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching product data", error);
        return error
    }
}

export const fetchPartnerProgramPageData = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/partner-program-page?populate=deep`
        );
        return response?.data
    } catch (error) {
      console.log("error");
      return error;
    }
}

export const fetchCareerDataBySearch = async (search: string) => {
  try {
    const response = await axios.get(
      `${process.env.admin_url}/api/job-positions?title=${encodeURIComponent(search)}`
    );
    return response?.data;
  } catch (error) {
    console.log("Error fetching Service data by slug", error);
    return error;
  }
};


export const fetchProjectPreviousAndNext = async (id: string) => {
  try {
    const response = await axios.get(
      `${process.env.admin_url}/api/projects/${id}/prevnext`
    );
    return response?.data
  } catch (error) {
    console.log("Error fetching product data", error);
    return error
  }
}


export const fetchFeaturedProjectData = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/projects?populate=deep&filters[isFeatured]=true`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching Client data", error);
        return error
    }
}


export const fetchProjectData = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/projects?populate=deep`
        );

        return response?.data
    } catch (error) {
        console.log("Error fetching product data", error);
        return error
    }
}


export const fetchProjectDataBySlug = async (slug: string) => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/projects?filters[slug][$eq]=${slug}&populate=deep`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching product data", error);
        return error
    }
}

export const fetchServiceDetailBySlug = async (slug: string) => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/service-details?filters[slug]=${slug}&populate=deep`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching Service data by slug", error);
        return error
    }
}


export const fetchServiceTitles = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/service-details?populate=*`
        );
        return response?.data
    } catch (error) {
        console.log("Error fetching Service data by slug", error);
        return error
    }
}

export const fetchSixServices = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/service-details?populate=*&pagination[limit]=3`
        );
        return response?.data
    } catch (error) {
      console.log("Error fetching Service data by slug", error);
      return error;
    }
}


export const fetchProductPageData = async () => {
    try {
        const response = await axios.get(
            `${process.env.admin_url}/api/product-page?populate=deep`
        );
        return response?.data
    } catch (error) {
      console.log("error");
      return error;
    }
}
