import { Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import Image from 'next/image';

import Link from 'next/link';
import { useRouter } from 'next/router';
import VGBG from '../vgbg';
import { fetchNavBarData } from '../../services/dynamicDataFetch';

export default function Navbar() {
    const router = useRouter();

    const [navData, setNavData] = useState<any>();

    useEffect(() => {
        const fetchData = async () => {
            const data: any = await fetchNavBarData();
            setNavData(data);
        };
        fetchData();
    }, []);

    const [submenuIsOpen, setSubMenuIsOpen] = useState(false);
    const toggleDropdown = () => {
        setSubMenuIsOpen(!submenuIsOpen);
    };
    const goToContactPage = (url: any) => {
        router.push(url);
    };

    const currentPathChecker = (href: any) => {
        const activePath = '/' + router.pathname.split('/')[1];
        if (activePath === href) return true;
        else false;
    };

    const currentPathCheckerForSubmenu = (
        submenu: { id: number; label: string; href: string }[]
    ) => {
        const activePath = '/' + router.pathname.split('/')[1];
        const isAnySubmenuActive = submenu.some(
            (submenuItem) => submenuItem?.href === activePath
        );
        return isAnySubmenuActive;
    };

    return (
        <Popover className="absolute top-0 w-full z-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6">
                <div className="flex justify-between items-center py-4 lg:justify-start lg:space-x-10">
                    <div className="flex justify-start lg:w-0 lg:flex-1 items-center">
                        <Link href="/" passHref>
                            <a>
                                <span className="sr-only">Virtual Gravity</span>
                                {navData?.CompanyLogo && (
                                    <Image
                                        height={40}
                                        width={120}
                                        className="w-auto"
                                        src={`${process.env.admin_url}${navData?.CompanyLogo?.data?.attributes?.url}`}
                                        alt=""
                                    />
                                )}
                            </a>
                        </Link>
                    </div>

                    {/* Mobile Menu Trigger Button */}
                    <div className="lg:hidden">
                        <Popover.Button className="bg-white bg-opacity-[8%] border border-white border-opacity-20 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500">
                            <span className="sr-only">Open menu</span>
                            {/* <span className="mr-1">Menu</span> */}
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                    </div>
                    <Popover.Group
                        as="nav"
                        className="hidden lg:flex space-x-8"
                    >
                        {navData &&
                            navData?.navItems?.data?.map((item: any) => (
                                <span key={`span-${item?.id}`}>
                                    {item?.attributes?.SubItems.length > 0 ? (
                                        <button
                                            onMouseEnter={toggleDropdown}
                                            onMouseLeave={toggleDropdown}
                                            className={`relative id-${item?.id}`}
                                            key={`button-${item?.id}`}
                                        >
                                            <div
                                                key={`div-${item?.id}`}
                                                className={
                                                    currentPathCheckerForSubmenu(
                                                        item?.attributes
                                                            ?.SubItems
                                                    )
                                                        ? 'relative text-orange flex gap-1 items-center hover-style-active'
                                                        : 'flex gap-1 items-center relative text-white hover-style'
                                                }
                                            >
                                                <p className="tiny font-semibold">
                                                    {item?.attributes?.Label}
                                                </p>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    className={`w-5 h-5 transition-all ${
                                                        submenuIsOpen &&
                                                        'rotate-180'
                                                    }`}
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </div>

                                            {submenuIsOpen &&
                                                item?.attributes?.SubItems
                                                    ?.length > 0 && (
                                                    <div className="absolute top-full left-1/2 -translate-x-1/2">
                                                        <ul className="mt-3 w-52 bg-dark-gradient rounded-lg shadow-md p-1 border border-white border-opacity-[20%]">
                                                            {item?.attributes?.SubItems?.map(
                                                                (
                                                                    subItem: any
                                                                ) => (
                                                                    <li
                                                                        key={`li-${subItem?.id}`}
                                                                    >
                                                                        <Link
                                                                            href={
                                                                                subItem?.url
                                                                            }
                                                                        >
                                                                            <a
                                                                                key={`submenu-${subItem?.id}`}
                                                                                className="text-white flex justify-between items-center px-4 py-3 hover:bg-white hover:bg-opacity-[8%] rounded-md"
                                                                            >
                                                                                <p className="tiny">
                                                                                    {
                                                                                        subItem.label
                                                                                    }
                                                                                </p>
                                                                                {currentPathChecker(
                                                                                    subItem?.url
                                                                                ) && (
                                                                                    <div className="w-[6px] h-[6px] rounded-full bg-secondary"></div>
                                                                                )}
                                                                            </a>
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                )}
                                        </button>
                                    ) : (
                                        <Link
                                            href={
                                                item?.attributes?.url
                                                    ? item?.attributes?.url
                                                    : '/'
                                            }
                                            key={`link-${item?.id}`}
                                        >
                                            <a
                                                key={`anchor-${item?.id}`}
                                                href={
                                                    item?.attributes?.url
                                                        ? item?.attributes?.url
                                                        : '/'
                                                }
                                                className={`${
                                                    currentPathChecker(
                                                        item?.attributes?.url
                                                            ? item?.attributes
                                                                  ?.url
                                                            : '/'
                                                    )
                                                        ? 'relative text-orange hover-style-active'
                                                        : 'relative text-white hover-style'
                                                } ${item.id}`}
                                            >
                                                <p className="tiny font-semibold">
                                                    {item?.attributes?.Label}
                                                </p>
                                            </a>
                                        </Link>
                                    )}
                                </span>
                            ))}
                    </Popover.Group>
                    {navData && navData?.Buttons?.data?.length > 0 && (
                        <div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0">
                            <button
                                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-8 py-[10px] rounded-full shadow-sm text-sm font-montserrat text-white bg-btn-gradient"
                                onClick={() => {
                                    goToContactPage(
                                        `${navData?.Buttons?.data?.[0]?.attributes?.url}`
                                    );
                                }}
                                style={{ fontSize: '14px' }}
                            >
                                {navData?.Buttons?.data?.[0]?.attributes?.Label}
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <Transition
                as={Fragment}
                enter="duration-400 ease-in"
                enterFrom="opacity-0 scale-95 -translate-x-full"
                enterTo="opacity-100 scale-100 translate-x-0"
                leave="duration-200 ease-out"
                leaveFrom="opacity-100 scale-100 translate-x-0"
                leaveTo="opacity-0 scale-95 -translate-x-full"
            >
                <Popover.Panel
                    focus
                    className="fixed top-0 h-screen inset-x-0 transition transform origin-top-right lg:hidden"
                >
                    <div className="bg-white bg-opacity-5 z-0 w-screen h-screen absolute left-0 top-0 backdrop-blur-sm"></div>
                    <div className="-mr-2 absolute right-4 top-4">
                        <Popover.Button className="bg-white bg-opacity-[8%] border border-white border-opacity-20 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500">
                            <span className="sr-only">Close menu</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                    </div>

                    <div className="absolute flex flex-col h-screen left-0 w-[70%] bg-dark-gradient border border-white border-opacity-20 z-10">
                        <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center">
                                <div>
                                    <Link href="/" passHref>
                                        <a>
                                            <span className="sr-only">
                                                Virtual Gravity
                                            </span>
                                            {navData && (
                                                <Image
                                                    height={36}
                                                    width={100}
                                                    className="w-auto"
                                                    src={`${process.env.admin_url}${navData?.CompanyLogo?.data?.attributes?.url}`}
                                                    alt=""
                                                />
                                            )}
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="px-5 h-full">
                            <div className="grid grid-cols-1 gap-x-8">
                                {navData &&
                                    navData?.navItems &&
                                    navData.navItems?.data?.map((item: any) => (
                                        <span key={`span-${item?.id}`}>
                                            {item?.attributes?.SubItems
                                                ?.length > 0 ? (
                                                <button
                                                    onClick={toggleDropdown}
                                                    className={`relative ${item?.id}`}
                                                    key={`mobile-button-${item?.id}`}
                                                >
                                                    <div
                                                        className={
                                                            currentPathCheckerForSubmenu(
                                                                item?.attributes
                                                                    ?.SubItems
                                                            )
                                                                ? 'p-3 bg-white bg-opacity-[8%] rounded-md relative text-orange flex justify-between items-center'
                                                                : 'p-3 flex justify-between items-center relative text-white'
                                                        }
                                                    >
                                                        <p className="tiny font-medium">
                                                            {
                                                                item?.attributes
                                                                    ?.Label
                                                            }
                                                        </p>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                            className={`w-5 h-5 transition-all ${
                                                                submenuIsOpen &&
                                                                'rotate-180'
                                                            }`}
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <ul
                                                        className={`ml-4 rounded-lg transition-all duration-300 ${
                                                            submenuIsOpen &&
                                                            item?.attributes
                                                                ?.SubItems
                                                                ?.length > 0
                                                                ? 'opacity-100 translate-y-0 max-h-[125px] p-1'
                                                                : 'opacity-0 -translate-y-4 overflow-hidden max-h-0'
                                                        }`}
                                                    >
                                                        {item?.attributes?.SubItems?.map(
                                                            (subItem: any) => (
                                                                <li
                                                                    key={`mobile-submenu-${subItem?.id}`}
                                                                >
                                                                    <Link
                                                                        href={
                                                                            subItem?.url
                                                                                ? subItem?.url
                                                                                : '/'
                                                                        }
                                                                        key={`mobile-link-${subItem?.id}`}
                                                                    >
                                                                        <a className="text-white flex justify-between items-center py-2 px-3 hover:bg-white hover:bg-opacity-[8%] rounded-md">
                                                                            <Popover.Button
                                                                                className={
                                                                                    'w-full'
                                                                                }
                                                                            >
                                                                                <p className="tiny font-medium opacity-70 text-left">
                                                                                    {
                                                                                        subItem?.label
                                                                                    }
                                                                                </p>
                                                                            </Popover.Button>

                                                                            {currentPathChecker(
                                                                                subItem?.url
                                                                            ) && (
                                                                                <div className="w-[7px] h-[7px] rounded-full bg-secondary"></div>
                                                                            )}
                                                                        </a>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </button>
                                            ) : (
                                                <Link
                                                    href={
                                                        item?.attributes?.url
                                                            ? item?.attributes
                                                                  ?.url
                                                            : '/'
                                                    }
                                                    key={`mobile-link-${item?.id}`}
                                                >
                                                    <a
                                                        key={`anchor-${item?.id}`}
                                                        className={
                                                            currentPathChecker(
                                                                item?.attributes
                                                                    ?.url
                                                            )
                                                                ? 'p-3 bg-white bg-opacity-[8%] flex items-center justify-between rounded-md relative text-orange'
                                                                : 'p-3 relative text-white'
                                                        }
                                                    >
                                                        <Popover.Button
                                                            className={'w-full'}
                                                        >
                                                            <p className="tiny font-medium text-left">
                                                                {
                                                                    item
                                                                        ?.attributes
                                                                        ?.Label
                                                                }
                                                            </p>
                                                        </Popover.Button>
                                                        {currentPathChecker(
                                                            item?.attributes
                                                                ?.url
                                                        ) && (
                                                            <div className="w-[7px] h-[7px] rounded-full bg-secondary"></div>
                                                        )}
                                                    </a>
                                                </Link>
                                            )}
                                        </span>
                                    ))}
                            </div>
                        </div>
                        {navData && navData?.Buttons?.data?.length > 0 && (
                            <div className="px-5 py-6 justify-self-end">
                                <button
                                    className="whitespace-nowrap inline-flex items-center justify-center px-8 py-[10px] rounded-full shadow-sm text-sm font-montserrat text-white bg-btn-gradient w-full"
                                    onClick={() => {
                                        goToContactPage(
                                            `${navData?.Buttons?.data?.[0]?.attributes?.url}`
                                        );
                                    }}
                                    style={{ fontSize: '14px' }}
                                >
                                    {
                                        navData?.Buttons?.data?.[0]?.attributes
                                            ?.Label
                                    }
                                </button>
                            </div>
                        )}
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
}
